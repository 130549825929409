import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby';

import withTheme from '../theme';

import { Layout } from '../components1/layout';
import { Text, Title } from '../components1/Texts';
import LinedTitle from '../components1/LinedTitle';
import FlexDiv from '../components1/FlexDiv';
import SectionRecettes from '../containers/sections/4-recettes';
import { CabadiciButton } from '../components1/buttons';
import Separator from '../components1/Separator';

const recettesData = [
    {
        title: 'Prêtes en 30 minutes',
        text: 'Sauf une, pour les moins pressés',
    },
    {
        title: 'De saison, toujours',
        text:
            'Pour mettre en valeur les produits de notre région au bon moment',
    },
    {
        title: 'Faciles à réaliser',
        text:
            "Pas besoin d'être un grand cuisinier\nNi d'avoir du matériel de pointe",
    },
    {
        title: 'Familiales',
        text:
            'Des ingrédients faciles à accepter même par les plus fins palais',
    },
    {
        title: '« Robotisables »',
        text: '2 versions pour chaque recette : avec un robot cuiseur, ou sans',
    },
    {
        title: 'Gourmandes',
        text: 'Et même surprenantes parfois',
    },
];

const FirstFlexDiv = styled(FlexDiv)`
    padding-top: 40px;

    @media only screen and (max-width: 599px) {
        padding-top: 15px;
    }
`;

const TitleResponsive = styled(Title)`
    @media only screen and (max-width: 599px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

const IndexPage = withTheme(({ theme, data }) => (
    <Layout>
        {
            ({ openConnectForm }) => (
                <>
                    <FirstFlexDiv style={{paddingBottom: 40}}>
                        <Separator
                            style={{flex: 1}}
                            strokeColor="rougeFort"
                            strokeWidth="2.5em"
                        />
                        <TitleResponsive style={{flex: 1, flexGrow: 1, textAlign: 'center', padding: 0, whiteSpace: 'nowrap'}}>Les recettes</TitleResponsive>
                        <Separator
                            style={{flex: 1}}
                            strokeColor="rougeFort"
                            strokeWidth="2.5em"
                        />
                    </FirstFlexDiv>
                    <BackgroundImage
                        fluid={data.backgroundContenuRecettes.childImageSharp.fluid}
                        style={{
                            // default values:
                            // backgroundRepeat: 'no-repeat',
                            // backgroundSize: 'cover',
                            // backgroundPosition: 'center',

                            // backgroundSize: '100% 100%',
                            objectFit: 'contain',

                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flex: 1,
                        }}
                        fadeIn={false}
                        css={`
                            @media only screen and (max-width: 599px) {
                                /* */
                            }
                        `}
                    >
                        <div
                            style={{
                                alignSelf: 'center',
                            }}
                            css={`
                                max-width: 33%;
                                @media only screen and (max-width: 599px) {
                                    max-width: 50%;
                                }
                            `}
                        >
                            {
                                recettesData.map(({ title, text }, index) => (
                                    <div
                                        key={index.toString()}
                                        style={{
                                            flex: '0 0 auto',
                                            backgroundColor: '#fff',
                                            paddingBottom: 20,
                                            borderBottom: '1px solid #fff',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: 600,
                                                textAlign: 'center',
                                                paddingBottom: 0
                                            }}>
                                            {title}
                                        </Text>
                                        <Text
                                            style={{
                                                textAlign: 'center',
                                                whiteSpace: 'pre-line',
                                                paddingTop: 0
                                            }}>
                                            {text}
                                        </Text>
                                    </div>
                                ))
                            }
                        </div>
                    </BackgroundImage>
                    <FlexDiv
                        style={{
                            backgroundColor: theme.color.beigeFaible,
                            paddingTop: 10,
                            paddingBottom: 10,
                            justifyContent: 'center',
                            marginTop: 40,
                            marginBottom: 40,
                        }}>
                        <BackgroundImage
                            fluid={data.backgroundParenthesesNoiresRectangle.childImageSharp.fluid}
                            style={{
                                // default values:
                                // backgroundRepeat: 'no-repeat',
                                // backgroundSize: 'cover',
                                // backgroundPosition: 'center',

                                backgroundSize: '100% 100%',
                                objectFit: 'contain',

                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // flex: 1,

                                minHeight: 150,
                                minWidth: 150,
                            }}
                            fadeIn={false}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    margin: 10px 50px !important;
                                }
                            `}
                        >
                            <Text
                                style={{
                                    fontFamily: theme.font.title,
                                    fontSize: '1.5rem',
                                    textAlign: 'center',
                                    paddingLeft: 50,
                                    paddingRight: 50,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1rem !important;
                                        padding: 0px 20px !important;
                                    }
                                `}
                            >
                                4 recettes différentes chaque semaine
                                <br />+<br />1 recette coup de cœur du mois,
                                <br />
                                {"que l'on peut recommander plusieurs fois !"}
                            </Text>
                        </BackgroundImage>
                    </FlexDiv>
                    <SectionRecettes>
                        <FlexDiv
                            style={{
                                justifyContent: 'center',
                                paddingTop: 50,
                            }}>
                            <CabadiciButton
                                color="rougeFaible"
                                onClick={openConnectForm}
                                style={{width: 170}}
                            >
                                commander
                            </CabadiciButton>
                        </FlexDiv>
                    </SectionRecettes>
                </>
            )
        }
    </Layout>
));

export default IndexPage;

export const pageQuery = graphql`
    query {
        backgroundContenuRecettes: file(relativePath: { eq: "backgrounds/header.jpg" }) {
            childImageSharp {
                fluid(maxHeight: 720, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgroundParenthesesNoiresRectangle: file(relativePath: { eq: "backgrounds/parentheses-noires-rectangle.png" }) {
            childImageSharp {
                fluid(maxHeight: 200, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
