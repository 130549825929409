import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import Section from '../../components1/Section';
import { Text, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';
import { CabadiciButton } from '../../components1/buttons';
import Separator from '../../components1/Separator';
import RecipeDateSelector from '../RecipeSelector';

const Section4 = ({ isIndex, children }) => {
    const data = useStaticQuery(graphql`
        query {
            robot: file(relativePath: { eq: "index/robot.png" }) {
                childImageSharp {
                    fixed(height: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            robotMobile: file(relativePath: { eq: "index/robot.png" }) {
                childImageSharp {
                    fixed(height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    return (
        <div style={{ marginBottom: '20px' }}>
            <Section id="section4">
                <FlexDiv style={{ paddingTop: '30px', justifyContent: 'center' }}>
                    <Title
                        style={{
                            padding: 0,
                            textAlign: 'center',
                        }}
                        css={`
                            color: ${props => props.theme.color.rougeFaible};
                            @media only screen and (max-width: 599px) {
                                font-size: 1.5rem !important;
                            }
                        `}
                    >
                        Les recettes
                    </Title>
                </FlexDiv>
                <RecipeDateSelector />
                {
                    isIndex && (<FlexDiv
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                        }}>
                        <FlexDiv
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 10,
                            }}>
                            <Image
                                fixed={data.robot.childImageSharp.fixed}
                                alt="robot"
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                                imgStyle={{
                                    objectFit: 'contain',
                                }}
                            />
                            <Image
                                fixed={data.robotMobile.childImageSharp.fixed}
                                alt="robot"
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                                imgStyle={{
                                    objectFit: 'contain',
                                }}
                            />
                        </FlexDiv>
                        <Text
                            style={{
                                fontSize: '1.125rem',
                                textAlign: 'justify',
                                textAlignLast: 'center',
                                maxWidth: 380,
                                paddingBottom: 30,
                                paddingTop: 10,
                            }}
                            css={`
                                font-family: ${props => props.theme.font.title};
                                @media only screen and (max-width: 599px) {
                                    font-size: 0.875rem !important;
                                    padding: 10px 20px 10px 20px;
                                }
                            `}
                        >
                            Chaque recette est écrite en 2 versions :
                            <br />
                            cuisson au robot qui fait tout
                            <br />
                            ou cuisson traditionnelle
                        </Text>
                    </FlexDiv>)
                }
                {
                    children || (
                        <FlexDiv
                            style={{
                                justifyContent: 'center',
                            }}>
                            <Link style={{ textDecoration: 'none' }} to="/recettes">
                                <CabadiciButton color="rougeFaible">
                                    en savoir plus
                                </CabadiciButton>
                            </Link>
                        </FlexDiv>
                    )
                }
            </Section>
            <Separator strokeColor="vertFaible" />
        </div>
    )
};

Section4.defaultProps = {
    isIndex: false,
};

Section4.propTypes = {
    isIndex: PropTypes.bool,
    children: PropTypes.element,
};

export default Section4;
